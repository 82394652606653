<div *ngIf="showGlobalLoader">
    <div class="loader-overlay d-flex flex-column">
        <div class="spinner-wrapper">
            <svg class="main-spinner" x="0" y="0" viewBox="0 0 40 40">
                <circle cx="20" cy="20" r="18"></circle>
            </svg>
        </div>
        <div class="loading-text pt-2">{{'Common.GlobalLoading' | translate}}</div>
    </div>
</div>
